import React from "react";

import translation from "./translation";

const Footer = ({ englishMode }) => [
  <section className="section section--sandpaper section--left-right" id="access">
    <div id="access-point"></div>
    <div className="container">
      <div className="section__left">
        <h2>{ translation('店舗案内', englishMode) }</h2>
        {
          englishMode ? <>
          <p>436 Uoya-cho, Nakagyo-ku, Kyoto-shi, Kyoto,<br />604-8082 Japan</p>
          <p><a href='tel:+810757446786'>+81-75-744-6786</a></p>
          <p>10-minute walk from Marutamachi Station on the Karasuma subway line<br />10-minute walk from Jingu-marutamachi Station on the Keihan Main Line<br />3-minute walk from Saibanshomae bus stop<br />A coin-operated parking lot is located nearby.</p>
          </> :
          <>
            <p>
              京都府京都市中京区魚屋町436<br />
              <a href='tel:+810757446786'>075-744-6786</a>
            </p>
            <p>
              地下鉄烏丸線丸太町駅より徒歩10分<br />
              京阪本線神宮丸太町駅より徒歩10分<br />
              バス停裁判所前より徒歩3分<br />
              最寄りにコインパーキング有り
            </p>
          </>
        }
        <a href="https://www.google.com/maps/place/%E6%97%A5%E6%9C%AC%E6%96%99%E7%90%86+%E7%9F%A2%E3%81%AE/@35.0162417,135.7654,15z/data=!4m6!3m5!1s0x60010968d9246c8f:0xcb33efa8cd6689b2!8m2!3d35.0162417!4d135.7654!16s%2Fg%2F11srrpchx2" target="_blank" className="style-link">
          Google map
        </a>
      </div>
      <div className="section__right" style={{textAlign: "center"}}>
        <img src="/images/motif.png" className="neutralize-bottom-padding" />
      </div>
    </div>
  </section>
,
<section className="section section--sandpaper section--left-right" id="footer-reserve">
  <div className="container">
    <div className="section__left">{
      englishMode ? <> 
      <p>Business hours: Reservations only.<br />18:00-22:00, closed on Wednesdays</p>
      <p>Plan:<br /> ¥27,500~ (tax included, service charge not included 10%), <br />Menu and price can be customized.<br /> Special room: ¥30,000.<br />Cancellation fee: 50% on the day before, 100% on the day</p>
      <p style={{marginTop: "-0.5em"}}><small>インボイス制度 登録番号事業者</small></p>
      </>:
      <>
        <h2>日本料理 矢の</h2>
        <p>営業時間</p>
        <p>
          完全予約制　18:00 ~ 22:00<br />
          水曜日定休
        </p>
        <p>ご予算</p>
        <p>
          ¥27,500~(税込・サービス料別10%)<br />
          ※内容・ご予算は応相談<br />
          特別室　¥30,000<br />
          キャンセル料：前日50%、当日100%
        </p>
        <p style={{marginTop: "-0.5em"}}><small>インボイス制度 登録番号事業者</small></p>
      </>
    }
      <a className="style-link" target="_blank" href={englishMode ? 'https://www.tablecheck.com/en/shops/yano-kyoto/reserve' : 'https://www.tablecheck.com/shops/yano-kyoto/reserve'}>{ translation('ご予約', englishMode) }</a>
    </div>
    <div className="section__right logo-container">
      <img src="/images/logo.png" />
    </div>
  </div>
</section>]

export default Footer;